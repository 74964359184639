import { SetStateAction } from "react";

import { Sha256 } from "@aws-crypto/sha256-js";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { SignatureV4 } from "@aws-sdk/signature-v4";
import { Auth } from "aws-amplify";

import { readChunks } from "./ChunkReader";
import { getUserPref, JWT_TOKEN } from "../amplify/AmplifyCache";
import { getStage } from "../amplify/AmplifyClientProvider";
import {
  getConversationLambdaEndpoint,
  getWebMetadataFeedbackEndpoint,
  getWebOrchestratorEndpoint,
  REGION,
} from "../amplify/AmplifyConstants";

/**
 * Invoke a Lambda by its Lambda URL.
 * Handle response streaming.
 *
 * @returns
 * @param action
 */
export const invokeMetadataEndpoint = async (action: string) => {
  const metadataHandlerEndpoint = getWebMetadataFeedbackEndpoint(getStage()); // appConfig.metadataEndpoint; // finney-longx account
  const url = new URL(metadataHandlerEndpoint);
  const body = JSON.stringify({
    action: action,
  });
  const response = await fetchResponse(url, body);
  return await response.json();
};

/**
 * Invoke the conversation Lambda by its Lambda URL.
 *
 * @returns
 * @param action
 * @param pageIndex
 * @param pageSize
 * @param startDate
 * @param endDate
 * @param conversationId
 */
export const invokeConversationEndpoint = async (
  action: string,
  pageIndex: string,
  pageSize: string,
  conversationId: string,
  startDate?: string,
  endDate?: string
) => {
  const conversationEndpoint = getConversationLambdaEndpoint(getStage());
  const url = new URL(conversationEndpoint);
  const body = JSON.stringify({
    action: action,
    pageIndex: pageIndex,
    pageSize: pageSize,
    startDate: startDate,
    endDate: endDate,
    conversationId: conversationId,
  });
  const response = await fetchResponse(url, body);
  return await response.json();
};

/**
 * Retrieves FinQ&A Metadata when page loads
 */
export async function getFinQAMetadata(
  userAlias: string,
  setFinqnaMetadata: (metadata: any) => void,
  setBusinessTenants: (businessTenants: any) => void
) {
  if (userAlias !== "bootstrapping") {
    const finqaMetadata = (await invokeMetadataEndpoint("get-finqna-metadata")).body;
    setFinqnaMetadata(finqaMetadata);
    const businessTenants = finqaMetadata.map((tenant: any) => {
      return {
        id: tenant.key,
        text: tenant.key,
      };
    });
    setBusinessTenants(businessTenants);
  }
}

/**
 * Build Request and fetches response from the API
 * @param url
 * @param requestBody
 */
export async function fetchResponse(url: URL, requestBody: any) {
  // set up the HTTP request
  const request = new HttpRequest({
    hostname: url.hostname,
    path: url.pathname,
    body: requestBody,
    method: "POST",
    headers: {
      jwtToken: getUserPref(JWT_TOKEN),
      "Content-Type": "application/json",
      host: url.hostname,
    },
  });
  // create a signer object with the credentials, the service name and the region
  // set up the url request to be signed for sigv4
  const currentCreds = await Auth.currentUserCredentials();
  const signer = new SignatureV4({
    credentials: currentCreds,
    service: "lambda",
    region: REGION,
    sha256: Sha256,
  });

  // sign the request and extract the signed headers, body and method
  const { headers, body, method } = await signer.sign(request);

  return await fetch(url, {
    method: method,
    headers: headers,
    body: body,
    mode: "cors",
    cache: "no-cache",
  });
}

/**
 * Invoke a Lambda by its Lambda URL.
 * Handle response streaming.
 *
 * @param userInput
 * @param persona
 * @param setChatPanelContents
 * @param chatPanelContents
 * @param addToDebugMessages
 * @param autoSpeak
 * @param businessTenantSelected
 * @param docTypeSelected
 * @param docSelected
 * @returns the full message (llmAnswerChunks) returned will be used as Finney's message (answer) in the History
 */
export const lambdaUrlStreaming = async (
  userInput: string,
  conversationId: string,
  persona: string,
  setChatPanelContents: {
    (value: SetStateAction<string>): void;
    (content: string): void;
  },
  chatPanelContents: string,
  addToDebugMessages: { (date: string): void; (trace: string): void },
  autoSpeak: boolean,
  businessTenantSelected: string,
  docTypeSelected: string,
  docSelected: string
) => {
  let chatPanelDom = document.getElementById("chat-panel")!;
  const debugMessagesDom = document.getElementById("debug-messages-panel")!.firstChild! as HTMLElement;

  let liveRenderingFinneyAnswerContents = ""; // render the answer as a stream
  let finalFinneyAnswerChunks: string[] = []; // used to only capture LLM answer text

  let webOrchestratorEndpoint = getWebOrchestratorEndpoint(getStage());
  const url = new URL(webOrchestratorEndpoint);

  const body = JSON.stringify({
    userTask: userInput,
    conversationId: conversationId,
    skill: persona,
    businessTenant: businessTenantSelected,
    docType: docTypeSelected,
    doc: docSelected,
  });

  const response = await fetchResponse(url, body);

  // errors handling for response
  if (!response.ok && !response.body) {
    console.error("Finney response error", response);

    liveRenderingFinneyAnswerContents += `<[ERROR]>\n`;
    liveRenderingFinneyAnswerContents += `\n`;
    setChatPanelContents(chatPanelContents + liveRenderingFinneyAnswerContents);

    finalFinneyAnswerChunks.push(
      "ERROR happened. Please try again with a new conversation. If problem perisits, please contact Finney Support."
    );

    return {
      messageId: `random-${Date.now()}`,
      finalAnswer: finalFinneyAnswerChunks.join(""),
      referencedSources: [],
    }; // isDone
  }

  const { messageId, _conversationId, finalAnswer, referencedSources, smartRoutingDetectedSkill } = await readChunks(
    response,
    chatPanelDom,
    debugMessagesDom,
    setChatPanelContents,
    addToDebugMessages,
    autoSpeak
  );

  return {
    messageId: messageId,
    _conversationId: _conversationId,
    finalAnswer: finalAnswer,
    referencedSources: referencedSources,
    smartRoutingDetectedSkill: smartRoutingDetectedSkill,
  }; // isDone
};
