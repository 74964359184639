import React, { useEffect, useState } from "react";

import { DateRangePickerProps } from "@amzn/awsui-components-react/uxdg";
import { Button, DateRangePicker, Header, HelpPanel, Spinner } from "@amzn/awsui-components-react-v3/polaris";
import { useNavigate } from "react-router-dom";

import { ERROR_ANSWER } from "../../../common/constants";
import { Conversation } from "../../../common/types/Conversation";
import { ChatMessageProps } from "../../../components/chatbot/message/ChatMessage";
import { getConversationHistory, getMessagesByConversationId } from "../../../utils/CommonUtils";

interface SideNavProps {
  setConversation: (value: string) => void;
  setDebug: (value: string[]) => void;
  setMessageCount: (value: number) => void;
  setMessageHistory: (value: ChatMessageProps[]) => void;
  autoSpeak: boolean;
  skills: any;
  userAlias: string;
}

export default function SideNav(sideNavProps: SideNavProps) {
  const navigate = useNavigate();
  const [todayList, setTodayList] = useState<any>([]);
  const [pastSevenDaysList, setPastSevenDaysList] = useState<any>([]);
  const [thisMonthList, setThisMonthList] = useState<any>([]);
  const [lastMonthList, setLastMonthList] = useState<any>([]);
  const [olderList, setOlderList] = useState<any>([]);
  const [datePickerList, setDatePickerList] = useState<any>([]);
  const [dateRange, setDateRange] = React.useState<DateRangePickerProps.AbsoluteValue | null>(null);
  const [isDpLoading, setIsDpLoading] = useState<boolean>(false);
  const [dpLoadMore, setDpLoadMore] = useState<boolean>(false);
  const [dpPageIndex, setDpPageIndex] = useState<number>(1);
  let today: Conversation[] = [];
  let pastSevenDays: Conversation[] = [];
  let thisMonth: Conversation[] = [];
  let lastMonth: Conversation[] = [];
  let olderConversations: Conversation[] = [];
  let datePickerConversations: Conversation[] = [];
  const currentMonthNumber = new Date().getMonth();
  const lastMonthNumber = currentMonthNumber === 0 ? 11 : currentMonthNumber - 1;

  /**
   * When a conversation is selected, this function populates the messages, sets the conversationId,
   * sets the message count, clears the debug messages, and navigates to the conversation's skill.
   */
  async function openConversation(conversation: Conversation) {
    sideNavProps.setMessageHistory([]);
    createConversationLists();
    const skillLabel = sideNavProps.skills.find((skill: any) => skill.value === conversation.detectedSkillId);
    let messageObjects: any = [];
    let isNextPageAvailable = true;
    let pageIndex = 1;
    while (isNextPageAvailable) {
      let messageResponse = await getMessagesByConversationId(conversation.conversationId, pageIndex.toString(), "10");
      isNextPageAvailable = messageResponse.isNextPageAvailable;
      pageIndex = pageIndex + 1;
      messageObjects = messageObjects.concat(messageResponse.messages);
    }
    messageObjects.sort((a: any, b: any) => a.tsReceived.localeCompare(b.tsReceived));
    let _messageHistory = [];
    for (const messageObject of messageObjects) {
      // Push user message
      _messageHistory.push({
        autoSpeak: sideNavProps.autoSpeak,
        timestamp: messageObject.tsReceived,
        messageId: messageObject.messageId,
        conversationId: messageObject.conversationId,
        message: messageObject.task,
        skill: skillLabel,
        staticReferenceSources: [],
        referencedSources: [],
        citations: [],
        owner: {
          isSelf: true,
          isAI: false,
          avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${sideNavProps.userAlias}`,
          name: sideNavProps.userAlias + "@",
          alias: sideNavProps.userAlias,
        },
      });
      // Push AI response
      let showReportFeedbacks = false;
      let rating = "";
      let feedbacks = "";
      let thumbsUp = false;
      let thumbsDown = false;
      if (messageObject.rating === "WEB:GOOD") {
        thumbsUp = true;
      } else if (messageObject.rating != undefined) {
        rating = messageObject.rating;
        feedbacks = messageObject.feedback;
        thumbsDown = true;
      }
      let answerMessage = ERROR_ANSWER;
      let answerReferencedSources = [];
      if (messageObject.answerContent) {
        const answerObject = JSON.parse(messageObject.answerContent);
        answerMessage = answerObject.qAnswer ?? answerObject.finalAnswer ?? answerObject.answer;
        answerReferencedSources = answerObject.qReferencedSources ?? answerObject.referencedSources;
      }
      _messageHistory.push({
        autoSpeak: sideNavProps.autoSpeak,
        timestamp: messageObject.tsReceivedIH ?? messageObject.tsReceived,
        messageId: messageObject.messageId,
        conversationId: messageObject.conversationId,
        message: answerMessage,
        skill: skillLabel,
        staticReferenceSources:
          conversation.detectedSkillId === "sna-web-mec"
            ? ["https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home"]
            : [],
        referencedSources: answerReferencedSources,
        citations: [],
        owner: {
          isSelf: false,
          isAI: true,
          avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${sideNavProps.userAlias}`,
          name: sideNavProps.userAlias + "@",
          alias: sideNavProps.userAlias,
        },
        showReportFeedbacks: showReportFeedbacks,
        rating: rating,
        feedbacks: feedbacks,
        thumbsUp: thumbsUp,
        thumbsDown: thumbsDown,
      });
    }
    sideNavProps.setMessageHistory(_messageHistory);
    sideNavProps.setConversation(conversation.conversationId);
    sideNavProps.setMessageCount(conversation.messagesCount);
    sideNavProps.setDebug([]);
    navigate(`/skills/${conversation.detectedSkillId}?autoSpeak=${sideNavProps.autoSpeak}`);
  }

  const createConversationLists = () => {
    (async () => {
      const conversationHistoryResponse = await getConversationHistory("", "", "1", "10");
      const conversationHistory = conversationHistoryResponse.conversations;
      // Filter the conversations by last message time
      today = [];
      pastSevenDays = [];
      thisMonth = [];
      lastMonth = [];
      olderConversations = [];
      const now = new Date();
      for (const conversation of conversationHistory) {
        const lastMessage = new Date(conversation.updated);
        if (lastMessage.toString().substr(0, 10) === now.toString().substr(0, 10)) {
          today.push(conversation);
        } else if (lastMessage.getTime() > now.getTime() - 7 * 24 * 60 * 60 * 1000) {
          pastSevenDays.push(conversation);
        } else if (now.getDate() > 7 && lastMessage.getMonth() === currentMonthNumber) {
          thisMonth.push(conversation);
        } else if (lastMessage.getMonth() === lastMonthNumber) {
          lastMonth.push(conversation);
        } else {
          olderConversations.push(conversation);
        }
      }
      // Create the conversation UI elements
      setTodayList(
        today.map((todayConversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(todayConversation)}>
              {todayConversation.conversationTopic}
            </Button>
          </div>
        ))
      );
      setPastSevenDaysList(
        pastSevenDays.map((pastSevenDaysConversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(pastSevenDaysConversation)}>
              {pastSevenDaysConversation.conversationTopic}
            </Button>
          </div>
        ))
      );
      setThisMonthList(
        thisMonth.map((thisMonthConversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(thisMonthConversation)}>
              {thisMonthConversation.conversationTopic}
            </Button>
          </div>
        ))
      );
      setLastMonthList(
        lastMonth.map((lastMonthConversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(lastMonthConversation)}>
              {lastMonthConversation.conversationTopic}
            </Button>
          </div>
        ))
      );
      setOlderList(
        olderConversations.map((olderConversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(olderConversation)}>
              {olderConversation.conversationTopic}
            </Button>
          </div>
        ))
      );
    })();
  };

  useEffect(() => {
    createConversationLists();
  }, []);

  const createDpConversationLists = () => {
    (async () => {
      if (dateRange) {
        setDpPageIndex(1);
        setIsDpLoading(true);
        const conversationHistoryResponse = await getConversationHistory(
          dateRange.startDate,
          dateRange.endDate,
          "1",
          "10"
        );
        const conversationHistory = conversationHistoryResponse.conversations;
        setDpLoadMore(conversationHistoryResponse.isNextPageAvailable);
        if (conversationHistoryResponse.isNextPageAvailable) {
          setDpPageIndex(dpPageIndex + 1);
        }
        datePickerConversations = conversationHistory;

        setDatePickerList(
          datePickerConversations.map((conversation: Conversation) => (
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Button variant="inline-link" onClick={() => openConversation(conversation)}>
                {conversation.conversationTopic}
              </Button>
            </div>
          ))
        );
        setIsDpLoading(false);
      }
    })();
  };

  useEffect(() => {
    createDpConversationLists();
  }, [dateRange]);

  async function loadMore() {
    if (dateRange) {
      setIsDpLoading(true);
      const conversationHistoryResponse = await getConversationHistory(
        dateRange.startDate,
        dateRange.endDate,
        dpPageIndex.toString(),
        "10"
      );
      const conversationHistory = conversationHistoryResponse.conversations;
      setDpLoadMore(conversationHistoryResponse.isNextPageAvailable);
      if (conversationHistoryResponse.isNextPageAvailable) {
        setDpPageIndex(dpPageIndex + 1);
      }

      for (const conversation of conversationHistory) {
        datePickerConversations.push(conversation);
      }

      const newDatePickerElements = datePickerList.concat(
        datePickerConversations.map((conversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(conversation)}>
              {conversation.conversationTopic}
            </Button>
          </div>
        ))
      );
      setDatePickerList(newDatePickerElements);
      setIsDpLoading(false);
    }
  }

  return (
    <HelpPanel header="⠀">
      <Header
        variant="h2"
        actions={
          <Button
            data-testid="new-conversation-button"
            ariaLabel="New conversation"
            variant="icon"
            iconName="add-plus"
            onClick={() => {
              location.reload();
            }}
          />
        }
      >
        Conversations
      </Header>
      {todayList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">Today</Header>
        </div>
      ) : (
        <></>
      )}
      {todayList}
      {pastSevenDaysList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">Past 7 Days</Header>
        </div>
      ) : (
        <></>
      )}
      {pastSevenDaysList}
      {thisMonthList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">{new Date().toLocaleString("default", { month: "long" })}</Header>
        </div>
      ) : (
        <></>
      )}
      {thisMonthList}
      {lastMonthList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">{new Date(new Date().setDate(0)).toLocaleString("default", { month: "long" })}</Header>
        </div>
      ) : (
        <></>
      )}
      {lastMonthList}
      {olderList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">Older Conversations</Header>
        </div>
      ) : (
        <></>
      )}
      {olderList}
      <div style={{ overflow: "hidden", margin: "auto", borderRight: "0.5mm solid", marginTop: 30 }}>
        <DateRangePicker
          onChange={({ detail }) => setDateRange(detail.value as DateRangePickerProps.AbsoluteValue)}
          value={dateRange}
          relativeOptions={[]}
          isValidRange={(range) => {
            if (range?.type === "absolute") {
              const [startDateWithoutTime] = range.startDate.split("T");
              const [endDateWithoutTime] = range.endDate.split("T");
              if (!startDateWithoutTime || !endDateWithoutTime) {
                return {
                  valid: false,
                  errorMessage:
                    "The selected date range is incomplete. Select a start and end date for the date range.",
                };
              }
              if (new Date(range.startDate).getTime() - new Date(range.endDate).getTime() > 0) {
                return {
                  valid: false,
                  errorMessage: "The selected date range is invalid. The start date must be before the end date.",
                };
              }
            }
            return { valid: true };
          }}
          i18nStrings={{
            applyButtonLabel: "Apply",
            cancelButtonLabel: "Cancel",
          }}
          dateOnly
          expandToViewport
          placeholder="Filter by date"
          rangeSelectorMode="absolute-only"
        />
      </div>
      {isDpLoading ? (
        <div style={{ paddingTop: 10 }}>
          <Spinner size="large" />
        </div>
      ) : (
        <div>
          {datePickerList.length > 0 ? (
            <div style={{ paddingTop: 10 }}>
              <Header variant="h2">
                <p>
                  Conversations from <i>{dateRange!.startDate}</i> to <i>{dateRange!.endDate}</i>
                </p>
              </Header>
            </div>
          ) : (
            <></>
          )}
          {datePickerList}
        </div>
      )}
      {dpLoadMore ? (
        <div style={{ paddingTop: 10 }}>
          <Button variant="primary" onClick={() => loadMore()}>
            Load More
          </Button>
        </div>
      ) : (
        <></>
      )}
    </HelpPanel>
  );
}
